import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
import { Connect } from '@carbon/pictograms-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const ExpressiveList = makeShortcode("ExpressiveList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ExpressiveList>`}</inlineCode>{` component is meant to be used with the
`}<inlineCode parentName="p">{`expressive-content-list-group`}</inlineCode>{` className when grouped together. This component
is only intended for paragraph content and not meant to nest other components
within it. This can be used with a pictogram by importing a pictogram from our
library and passing it into the `}<inlineCode parentName="p">{`pictogram`}</inlineCode>{` prop as a node.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Content only</Title>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" background={true} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
  <ExpressiveList title="Short Title" background={true} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
  <ExpressiveList title="Short Title" background={true} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <p>{`Using `}<inlineCode parentName="p">{`titleType=expressive-04`}</inlineCode>{` `}<em parentName="p">{`please check props section for more info on
usage`}</em></p>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" titleType="expressive-04" background={true} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <p>{`Using `}<inlineCode parentName="p">{`titleType=expressive-02`}</inlineCode>{` `}<em parentName="p">{`please check props section for more info on
usage`}</em></p>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" titleType="expressive-02" background={true} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <Title mdxType="Title">With pictograms</Title>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" background={true} pictogram={<Connect aria-label="Add" className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
  <ExpressiveList title="Short Title" background={true} pictogram={<Connect aria-label="Add" className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
  <ExpressiveList title="Short Title" background={true} pictogram={<Connect aria-label="Add" className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <p>{`Using `}<inlineCode parentName="p">{`titleType=expressive-04`}</inlineCode>{` `}<em parentName="p">{`please check props section for more info on
usage`}</em></p>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" titleType="expressive-04" background={true} pictogram={<Connect aria-label="Add" className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <p>{`Using `}<inlineCode parentName="p">{`titleType=expressive-02`}</inlineCode>{` `}<em parentName="p">{`please check props section for more info on
usage`}</em></p>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Short Title" titleType="expressive-02" background={true} pictogram={<Connect aria-label="Add" className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
    Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus
    suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum
    vulputate. Orci ac auctor augue mauris augue neque. Nullam ac tortor vitae
    purus faucibus ornare suspendisse sed.
  </ExpressiveList>
    </div>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Content only</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ExpressiveList/ExpressiveList.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveList",
        "path": "components/ExpressiveList/ExpressiveList.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveList"
      }}>{`<div className="expressive-content-list-group">
  <ExpressiveList 
    title="Short Title"
    background="true">
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>

  <ExpressiveList 
    title="Short Title"
    background="true">
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>
    
  <ExpressiveList 
    title="Short Title"
    background="true">
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>
</div>
`}</code></pre>
    <Title mdxType="Title">With pictograms</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ExpressiveList/ExpressiveList.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveList",
        "path": "components/ExpressiveList/ExpressiveList.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveList"
      }}>{`import { Connect } from '@carbon/pictograms-react';

<div className="expressive-content-list-group">
  <ExpressiveList 
    title="Short Title"
    background="true"
    pictogram={<Connect className="my-custom-class" />}
    >
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>

  <ExpressiveList 
    title="Short Title"
    background="true"
    pictogram={<Connect className="my-custom-class" />}
    >
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>
    
  <ExpressiveList 
    title="Short Title"
    background="true"
    pictogram={<Connect className="my-custom-class" />}
    >
  Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate.
  </ExpressiveList>
</div>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use to add the content. Takes in text content only`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use to declare custom class name. This will be added on the outmost div`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`picogram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Takes in a custom pictogram node from `}<inlineCode parentName="td">{`carbon components`}</inlineCode>{` that is imported to the top of the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prop used for a short title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional prop used for type options - default is `}<inlineCode parentName="td">{`expressive-03`}</inlineCode>{`. Optional types are: `}<inlineCode parentName="td">{`expressive-04`}</inlineCode>{` and `}<inlineCode parentName="td">{`expressive-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      